import createRenderer from './createProfileRenderer';
import { PageComponentNames } from 'behavior/pages';
import editProfileRenderer from './editProfile';
import orderTemplatesRenderer from './orderTemplates';
import myAddressBookRender from './myAddressBook';

export default {
  [PageComponentNames.EditProfile]: createRenderer('YourDetails', editProfileRenderer, { linkKey: 'edit' }),
  [PageComponentNames.OrderTemplates]: createRenderer('OrderTemplates_Header', orderTemplatesRenderer, { linkKey: 'templates' }),
  [PageComponentNames.MyAddressBook]: createRenderer('MyAddressBook_Header', myAddressBookRender, { linkKey: 'myAddressBook' }),
};
