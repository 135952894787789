import { useMemo } from 'react';

type CreatedFn = (response: Response) => void;

export default function useBlob(fileName: string) {
  const [create] = useMemo(() => createFile(fileName), []);

  return create;
}

function createFile(fileName: string) {
  const create: CreatedFn = async response => {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return [create];
}