export const ACCOUNT_DETAILS_INFO_REQUESTED = 'ACCOUNT_DETAILS_INFO_REQUESTED';
export const loadAccountDetailsInfo = () => ({ type: ACCOUNT_DETAILS_INFO_REQUESTED });

export const ACCOUNT_DETAILS_INFO_RECEIVED = 'ACCOUNT_DETAILS_INFO_RECEIVED' as const;
export const accountDetailsInfoReceived = (accountDetails: AccountDetails) => ({
  type: ACCOUNT_DETAILS_INFO_RECEIVED,
  payload: { accountDetails },
});

export type AccountDetailsAction = ReturnType<typeof accountDetailsInfoReceived>;

type AccountDetails = {
  balance?: boolean;
  creditLimit?: boolean;
  accountId: string;
  paymentTerms: string;
};
