import { of } from 'rxjs';
import { SystemRouteData, RouteName } from 'routes';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { mergeMap, map } from 'rxjs/operators';
import { orderSubmitQuery } from './queries';
import { initContentSystemPage, loadContentSystemPageQuery } from 'behavior/pages/system';
import { routesBuilder } from 'routes';
import { navigateTo } from 'behavior/events';
import type { Handler } from 'behavior/pages/types';
import type { OrderSubmitPage, Transaction } from './types';
import { CheckoutProcessNames } from './constants';
import type { ContentSystemPageData } from '../system/content';

//Ticket 187109: [SCA] 3.3.Order placement with chop products
const handler: Handler<OrderSubmitRouteData, OrderSubmitPage> = (routeData, _state$, { api }) => {
  const { params: { transactionId, previewToken } } = routeData;

  if (previewToken) {
    return api.graphApi<OrderSubmitPreviewResponse>(loadContentSystemPageQuery('orderSubmit')).pipe(
      map(({ pages: { orderSubmit } }) => ({
        page: {
          ...initContentSystemPage(orderSubmit),
          component: PageComponentNames.OrderSubmit,
          transaction: {
            id: '',
            failed: false,
            cancelled: false,
            isPaymentError: false,
            checkoutProcessName: CheckoutProcessNames.Order,
            document: { id: '', documentId: '-', chopOrderNo: '' },
            submittedOffline: false,
            isTracked: true,
          },
        },
      })),
    );
  }

  if (!transactionId)
    return of(null);

  return api.graphApi<OrderSubmitPageResponse>(orderSubmitQuery, { id: transactionId }).pipe(
    mergeMap(({
      pages: { orderSubmit },
      paymentTransaction: transaction,
    }) => {
      if (transaction == null)
        return of(null);

      const { id, isPaymentError, failed, cancelled } = transaction;
      const redirect = (redirectRoute: SystemRouteData) => of({ action$: of(navigateTo(redirectRoute)) });

      if (isPaymentError)
        return redirect(routesBuilder.forPaymentError(id));
      else if (failed)
        return redirect(routesBuilder.forOrderFailed(id));
      else if (cancelled)
        return redirect(routesBuilder.forOrderCancelled(id));

      return of({
        page: {
          ...initContentSystemPage(orderSubmit),
          component: PageComponentNames.OrderSubmit as const,
          transaction,
        },
      });
    }),
  );
};

export default handler;

type OrderSubmitRouteData = {
  routeName: RouteName.OrderSubmit;
  params: {
    previewToken?: string;
    transactionId?: string;
  };
};

type OrderSubmitPreviewResponse = {
  pages: {
    orderSubmit: ContentSystemPageData;
  };
};

type OrderSubmitPageResponse = {
  pages: {
    orderSubmit: ContentSystemPageData;
  };
  paymentTransaction: Transaction;
};
