export const order = {
  id: '',
  currency: {
    id: '',
    symbol: '',
    cultureName: '',
    decimalDigits: 0,
    separator: '',
  },
  orderDate: '',
  referenceNo: '-',
  totalAmount: null,
};

export const paymentMethods = Array.from(Array(3)).map((_, index) => ({
  description: null,
  id: index.toString(),
  name: '',
  isDefault: false,
  imageUrl: null,
  cost: 0,
}));
