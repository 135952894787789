import { ChopItemImportRecord, InvalidItemImportRecord, InvalidItemImportResult, Line, Product } from './types';
import { parseCsvRecords } from 'utils/csvHelpers';
import { iEquals } from 'utils/helpers';
import { ArtOfMat, ExternalExact, RebateExact, ReversibleLongSideup, ReversibleShortSideup, Set2, NonReversibleMoulding } from './constants';

const defaultUom = {
  quantityStep: 1,
  maximumQuantity: 999,
  minimumQuantity: 1,
};

const ACCURACY = 3;

enum ColumnName {
  ProductId = 'AntonsCode',
  ServiceType = 'MeasurementType',
  Orientation = 'Orientation',
  Length = 'Length',
  Width = 'Width',
  Quantity = 'Quantity',
}

enum ErrorCodes {
  ProductIsInvalid = 'ProductIsInvalid',
  QuantityIsInvalid = 'QuantityIsInvalid',
  ServiceTypeIsInvalid = 'ServiceTypeIsInvalid',
  OrientationTypeIsInvalid = 'OrientationTypeIsInvalid',
  LengthIsInvalid = 'LengthIsInvalid',
  WidthIsInvalid = 'WidthIsInvalid',
}

type ValidationResult = {
  invalidRecords: InvalidItemImportResult[];
  lines: Line[];
};

export function processRecords(records: ChopItemImportRecord[], products: Product[]): ValidationResult {
  const invalidRecords = [];
  for (const record of records) {
    const { isChopItem, quantity, length, width, orientationType, serviceType } = record;
    const product = products.find(product => iEquals(product.id, record.productId));
    if (!product) {
      invalidRecords.push({ errorCodes: [ErrorCodes.ProductIsInvalid], record });
      continue;
    }

    const { uom, productChopSetType, reversible } = product;

    const currentUom = isChopItem ? defaultUom : uom;
    const maximumQuantity = currentUom?.maximumQuantity;
    const minimumQuantity = currentUom?.minimumQuantity;
    const quantityStep = currentUom?.quantityStep;
    const errorCodes = [];

    // quantityStep validation was copied from QuantityTextBox
    if (maximumQuantity && quantity > maximumQuantity)
      errorCodes.push(ErrorCodes.QuantityIsInvalid);
    else if (minimumQuantity && quantity < minimumQuantity)
      errorCodes.push(ErrorCodes.QuantityIsInvalid);
    else if (quantityStep && Number(quantity + 'e' + ACCURACY) % Number(quantityStep + 'e' + ACCURACY) !== 0)
      errorCodes.push(ErrorCodes.QuantityIsInvalid);

    if (isChopItem) {
      const { chopItemMinLength, chopItemMaxLength, chopItemMinWidth, chopItemMaxWidth } = product;

      if (!length || length > chopItemMaxLength || length < chopItemMinLength)
        errorCodes.push(ErrorCodes.LengthIsInvalid);

      if (!width || width > chopItemMaxWidth || width < chopItemMinWidth)
        errorCodes.push(ErrorCodes.WidthIsInvalid);

      if (productChopSetType === Set2) {
        if (serviceType && ![RebateExact, ExternalExact].includes(serviceType))
          errorCodes.push(ErrorCodes.ServiceTypeIsInvalid);

        if (reversible && (!orientationType || ![ReversibleLongSideup, ReversibleShortSideup].includes(orientationType)))
          errorCodes.push(ErrorCodes.OrientationTypeIsInvalid);
      } else if (serviceType && ![RebateExact, ArtOfMat, ExternalExact].includes(serviceType)) {
        errorCodes.push(ErrorCodes.ServiceTypeIsInvalid);
      }

      if (product.mainItem) {
        product.image = product.mainItem.image;
        product.reversible = product.mainItem.reversible;
      }
    }

    if (errorCodes.length)
      invalidRecords.push({ errorCodes, record });
  }

  if (invalidRecords.length)
    return {
      lines: [],
      invalidRecords,
    };

  return {
    lines: records.map(record => {
      const product = products.find(product => iEquals(product.id, record.productId))!;
      const isNonReversible = product.isChopItem && !product.reversible;
      return {
        quantity: record.quantity,
        length: record.length,
        width: record.width,
        serviceType: record.serviceType,
        mouldingType: isNonReversible ? NonReversibleMoulding : record.orientationType,
        product,
      };
    }),
    invalidRecords,
  };
}

export function parseNumber(number: any) {
  return (typeof number === 'string' && number !== '')
    ? parseFloat(number.replace(',', '.'))
    : number;
}

function parseRowFactory() {
  return ({
    [ColumnName.ProductId]: productId,
    [ColumnName.ServiceType]: serviceType,
    [ColumnName.Orientation]: orientationType,
    [ColumnName.Length]: lengthString,
    [ColumnName.Width]: widthString,
    [ColumnName.Quantity]: quantityString,
  }: Record<ColumnName, string>) => {
    const record = { productId };
    if (!productId?.trim())
      return { errorCodes: [ErrorCodes.ProductIsInvalid], record };

    const errorCodes = [];
    const quantity = parseNumber(quantityString);
    if (!quantity)
      errorCodes.push(ErrorCodes.QuantityIsInvalid);

    const length = parseNumber(lengthString);
    const width = parseNumber(widthString);
    const isChopItem = length || width || serviceType || orientationType;

    if (isChopItem) {
      if (!length)
        errorCodes.push(ErrorCodes.LengthIsInvalid);

      if (!width)
        errorCodes.push(ErrorCodes.WidthIsInvalid);

      if (!serviceType || ![RebateExact, ArtOfMat, ExternalExact].includes(serviceType))
        errorCodes.push(ErrorCodes.ServiceTypeIsInvalid);

      if (orientationType && ![ReversibleLongSideup, ReversibleShortSideup].includes(orientationType))
        errorCodes.push(ErrorCodes.OrientationTypeIsInvalid);
    }

    if (errorCodes.length)
      return { record, errorCodes };

    return {
      record: {
        productId,
        serviceType,
        orientationType,
        length,
        width,
        quantity,
        isChopItem: !!isChopItem,
      },
    };
  };
}

export function parseRecords(file: File, delimiter: string) {
  const columnsNames = Object.values(ColumnName);
  return parseCsvRecords<ChopItemImportRecord, InvalidItemImportRecord>(file, columnsNames, parseRowFactory(), columnsNames.length, delimiter);
}
