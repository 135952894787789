import type { FileGroup } from 'utils/api/files';

type ContactUsInput = {
  captchaToken?: string;
  customerData: Record<string, any>;
};

export const CONTACT_US_FORM_SUBMITTED = 'CONTACT_US/FORM/SUBMITTED' as const;
export const sendContactUsForm = (input: ContactUsInput, files: FileGroup[], updatedById: string) => ({
  type: CONTACT_US_FORM_SUBMITTED,
  payload: { input, files, updatedById },
});

export const CONTACT_US_RESPONSE_RECEIVED = 'CONTACT_US/RESPONSE/RECEIVED' as const;
export const receiveContactUsResponse = (success: boolean, updatedById: string) => ({
  type: CONTACT_US_RESPONSE_RECEIVED,
  payload: { success, updatedById },
});

export type ContactUsAction = ReturnType<
  | typeof sendContactUsForm
  | typeof receiveContactUsResponse
>;

