import htmlPackage from 'components/objects/htmlBlock/package';
import headingPackage from 'components/objects/headingBlock/package';
import vdColumnTemplatePackage from 'components/visualDesigner/package';
import productSetPackage from 'components/objects/productSet/package';
import imagePackage from 'components/objects/imageBlock/package';
import searchBarPackage from 'components/objects/searchBarBlock/package';
import spacerPackage from 'components/objects/spacerBlock/package';
import logoPackage from 'components/objects/logo/package';
import languageSelectorPackage from 'components/objects/languageSelector/package';
import basketSummaryPackage from 'components/objects/basket/summary/package';
import navigationPackage from 'components/objects/navigation/package';
import accountMenuPackage from 'components/objects/accountMenu/package';
import productSuggestionsPackage from 'components/objects/productSuggestions/package';
import callUsMessagePackage from 'components/objects/callUsMessage/package';
import copyrightTextPackage from 'components/objects/copyrightText/package';
import newsletterPackage from 'components/objects/newsletter/package';
import backToOverviewPackage from 'components/objects/backToOverviewBlock/package';
import breadcrumbPackage from 'components/objects/breadcrumbBlock/package';
import productPackage from 'components/objects/product/blocks/package';
import productGroupPackage from 'components/objects/productGroup/blocks/package';
import addThisPackage from 'components/objects/addThisBlock/package';
import lastViewedProductsPackage from 'components/objects/lastViewedProducts/package';
import blogPackage from 'components/objects/blog/blocks/package';
import sanaTextPackage from 'components/objects/sanaTextBlock/package';
import pageTitlePackage from 'components/objects/pageTitleBlock/package';
import linkButtonPackage from 'components/objects/linkButtonBlock/package';
import blogItemPackage from 'components/objects/blogItem/blocks/package';
import contactUsPackage from 'components/objects/contactUs/package';
import forgotPasswordPackage from 'components/objects/forgotPassword/package';
import resetPasswordPackage from 'components/objects/resetPassword/package';
import loginPackage from 'components/objects/login/blocks/package';
import profilePackage from 'components/objects/profile/blocks/package';
import registrationPackage from 'components/objects/registration/package';
import orderConfirmationPackage from 'components/objects/checkout/orderResult/blocks/package';
import invoicePaymentPackage from 'components/objects/invoicePayment/blocks/package';
import mediaGalleryPackage from 'components/objects/mediaGallery/package';
import chopQuickOrderPackage from 'components/objects/chopQuickOrderBlock/package';

const defaultComponents = {
  ...htmlPackage,
  ...headingPackage,
  ...vdColumnTemplatePackage,
  ...productSetPackage,
  ...imagePackage,
  ...searchBarPackage,
  ...spacerPackage,
  ...logoPackage,
  ...languageSelectorPackage,
  ...basketSummaryPackage,
  ...navigationPackage,
  ...accountMenuPackage,
  ...productSuggestionsPackage,
  ...callUsMessagePackage,
  ...copyrightTextPackage,
  ...newsletterPackage,
  ...backToOverviewPackage,
  ...breadcrumbPackage,
  ...productPackage,
  ...productGroupPackage,
  ...addThisPackage,
  ...lastViewedProductsPackage,
  ...blogPackage,
  ...sanaTextPackage,
  ...pageTitlePackage,
  ...linkButtonPackage,
  ...blogItemPackage,
  ...contactUsPackage,
  ...forgotPasswordPackage,
  ...resetPasswordPackage,
  ...loginPackage,
  ...profilePackage,
  ...registrationPackage,
  ...orderConfirmationPackage,
  ...invoicePaymentPackage,
  ...mediaGalleryPackage,
  ...chopQuickOrderPackage,
};

export default defaultComponents;
