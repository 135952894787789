import { LocationChangedAction } from './../events/index';
import { quickOrderPageSize } from './constants';
import { LOCATION_CHANGED, ViewerChangedAction } from 'behavior/events';
import type { 
  SaveQuickOrderTemplateResult, 
  ChopItemImportRecord, 
  InvalidItemImportResult, 
  Line,
} from './types';
import {
  CHOP_QUICK_ORDER_PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED,
  CHOP_QUICK_ORDER_ITEM_LINE_ADDED,
  CHOP_QUICK_ORDER_PRODUCT_SELECTOR_PRODUCT_CLEARED,
  CHOP_QUICK_ORDER_CHANGE_TOTAL_LINES_COUNT,
  CHOP_QUICK_ORDER_ITEM_LINES_CLEARED,
  ReceiveProductAction,
  ReceiveSearchSuggestionsAction,
  ChangeTotalLinesCountAction,
  ChopProductPageAction,
  CHOP_QUICK_ORDER_ITEM_LINE_REMOVED,
  RemoveProductAction,
  CHOP_QUICK_ORDER_IMPORT_PARSING_RESULT_SET,
  CHOP_QUICK_ORDER_IMPORT_ERROR_SET,
  SetErrorProductAction,
  SetParsingResultProductAction,
  CHOP_QUICK_ORDER_IMPORT_PARSING_RESULT_CLEAR,
  CHOP_QUICK_ORDER_IMPORT_RECORDS_UPLOADED,
  RecordsUploadedAction,
  CHOP_QUICK_ORDER_IMPORT_UPLOAD_RECORDS,
  ApplyCalculatedDataAction,
  CHOP_QUICK_ORDER_APPLY_CALCULATED_DATA,
  CHOP_QUICK_ORDER_TEMPLATE_SAVED,
  OrderTempalteSavedAction,
  CHOP_QUICK_ORDER_TEMPLATE_SAVE,
  LinesDataUpdatedAction,
  CHOP_QUICK_ORDER_LINES_DATA_UPDATED,
  CHOP_QUICK_ORDER_ALL_ITEM_LINES_REMOVED,
} from './actions';
import { createReducer } from 'utils/redux';

type State = {
  itemLines: (Line | null)[];
  templateSaveResult?: SaveQuickOrderTemplateResult;
  productSelector: ProductSelectorState;
  records: ChopItemImportRecord[];
  recordErrors: InvalidItemImportResult[];
  error?: unknown;
  isLoading: boolean;
};

const initialState: State = {
  itemLines: Array(quickOrderPageSize).fill(null),
  productSelector: {
    suggestions: [],
  },
  templateSaveResult: null,
  error: null,
  records: [],
  recordErrors: [],
  isLoading: false,
};

type ProductSelectorState = {
  suggestions: string[];
};

type Action =
  | ViewerChangedAction
  | ChopProductPageAction
  | LocationChangedAction;

export default createReducer<State, Action>(initialState, {
  [CHOP_QUICK_ORDER_PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED]: onSearchSuggestionsReceived,
  [CHOP_QUICK_ORDER_ITEM_LINE_ADDED]: updateItemLine,
  [CHOP_QUICK_ORDER_ITEM_LINE_REMOVED]: removeItemLine,
  [CHOP_QUICK_ORDER_ALL_ITEM_LINES_REMOVED]: removeAllItemLines,
  [CHOP_QUICK_ORDER_ITEM_LINES_CLEARED]: setInitialState,
  [CHOP_QUICK_ORDER_PRODUCT_SELECTOR_PRODUCT_CLEARED]: onProductCleared,
  [CHOP_QUICK_ORDER_CHANGE_TOTAL_LINES_COUNT]: onChangeTotalLinesCount,
  [CHOP_QUICK_ORDER_IMPORT_PARSING_RESULT_SET]: onSetParsingResult,
  [CHOP_QUICK_ORDER_IMPORT_ERROR_SET]: onSetError,
  [CHOP_QUICK_ORDER_IMPORT_PARSING_RESULT_CLEAR]: onClearParsingResult,
  [CHOP_QUICK_ORDER_IMPORT_UPLOAD_RECORDS]: onRecordsUploading,
  [CHOP_QUICK_ORDER_IMPORT_RECORDS_UPLOADED]: onRecordsUploaded,
  [CHOP_QUICK_ORDER_APPLY_CALCULATED_DATA]: onApplyCalculatedData,
  [CHOP_QUICK_ORDER_TEMPLATE_SAVED]: onQuickOrderTemplateSaved,
  [CHOP_QUICK_ORDER_LINES_DATA_UPDATED]: onLinesDataUpdated,
  [CHOP_QUICK_ORDER_TEMPLATE_SAVE]: setLoading,
  [LOCATION_CHANGED]: setInitialState,
});

function onSearchSuggestionsReceived(state: State, action: ReceiveSearchSuggestionsAction) {
  return { 
    ...state,
    productSelector: {
      ...state.productSelector,
      suggestions: action.payload.suggestionsIds,
    },
  };
}

function updateItemLine(state: State, action: ReceiveProductAction) {
  const { line, lineIndex } = action.payload;

  if (lineIndex >= state.itemLines.length)
    return state;

  const itemLines = [...state.itemLines];
  itemLines[lineIndex] = line;

  return { 
    ...state,
    itemLines,
    productSelector: {
      ...state.productSelector,
      suggestions: [],
    },
  };
}

function removeItemLine(state: State, action: RemoveProductAction) {
  const lineIndex = action.payload;

  if (lineIndex >= state.itemLines.length)
    return state;

  const itemLines = [...state.itemLines];
  itemLines[lineIndex] = null;

  return { 
    ...state,
    itemLines,
  };
}

function removeAllItemLines(state: State) {
  return { 
    ...state,
    itemLines: Array(quickOrderPageSize).fill(null),
  };
}

function onProductCleared(state: State) {
  return { ...state,
    productSelector: {
      ...state.productSelector,
      selectedProduct: null,
    },
  };
}

function onChangeTotalLinesCount(state: State, _action: ChangeTotalLinesCountAction) {
  return {
    ...state,
    itemLines: [
      ...state.itemLines,
      ...Array(quickOrderPageSize).fill(null),
    ],
  };
}

function onSetParsingResult(state: State, action: SetParsingResultProductAction) {
  const { records, recordErrors } = action.payload;
  return {
    ...state,
    records,
    recordErrors,
    error: null,
    isLoading: false,
  };
}

function onClearParsingResult(state: State) {
  return {
    ...state,
    records: [],
    recordErrors: [],
    error: null,
  };
}

function onSetError(state: State, action: SetErrorProductAction) {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
  };
}

function onRecordsUploading(state: State) {
  return {
    ...state,
    isLoading: true,
  };
}

function onRecordsUploaded(state: State, action: RecordsUploadedAction) {
  const { lines } = action.payload;
  
  const prevoiusLines = state.itemLines.filter(line => !!line);
  const itemLines = [...lines, ...prevoiusLines];

  const newTotalCount = prevoiusLines.length + lines.length;
  if (newTotalCount < state.itemLines.length) 
    itemLines.push(...Array(state.itemLines.length - newTotalCount).fill(null));

  return {
    ...state,
    itemLines,
    isLoading: false,
  };
}

function onApplyCalculatedData(state: State, action: ApplyCalculatedDataAction) {
  const { calculatedData } = action.payload;

  let isChanged = false;
  const updatedLines = state.itemLines.map((line, index) => {
    if (!line)
      return line;

    const calculatedLine = calculatedData.find(calculatedLine => calculatedLine.index === index);
    if (!calculatedLine)
      return line;

    const updatedLine = { ...line };
    if (calculatedLine.quantity) {
      updatedLine.quantity = calculatedLine.quantity;
      isChanged = true;
    }

    if (!line.product.isChopItem)
      return updatedLine;

    if ('length' in updatedLine && calculatedLine.length) {
      updatedLine.length = calculatedLine.length;
      isChanged = true;
    }

    if ('width' in updatedLine && calculatedLine.width) {
      updatedLine.width = calculatedLine.width;
      isChanged = true;
    }

    if ('serviceType' in updatedLine && calculatedLine.serviceType) {
      updatedLine.serviceType = calculatedLine.serviceType;
      isChanged = true;
    }

    if ('mouldingType' in updatedLine && calculatedLine.mouldingType) {
      updatedLine.mouldingType = calculatedLine.mouldingType;
      isChanged = true;
    }

    return updatedLine;
  });

  if (!isChanged)
    return state;

  return {
    ...state,
    itemLines: updatedLines,
  };
}

function setLoading(state: State) {
  return {
    ...state,
    isLoading: true,
  };
}

function onQuickOrderTemplateSaved(state: State, action: OrderTempalteSavedAction) {
  return {
    ...state,
    isLoading: false,
    templateSaveResult: action.payload.savingResult,
  };
}

function onLinesDataUpdated(state: State, action: LinesDataUpdatedAction) {
  const { lines } = action.payload;

  if (lines.length < state.itemLines.length) 
    lines.push(...Array(state.itemLines.length - lines.length).fill(null));

  return {
    ...state,
    itemLines: lines,
  };
}

function setInitialState(_state: State) {
  return initialState;
}