import { openOrdersPageQuery } from './queries';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { initPageContent } from '../system';
import { loadSystemPageQuery } from '../system/queries';
import { RouteName } from 'routes';
import { OpenOrdersItem } from './types';
import { Handler } from '../types';
import { AvailableSortingOptions } from './constants';

interface Filter {
  orderDate: {
    from: string | null;
  };
  sortField: string | null;
}

type Params = {
  filter: Filter;
  index?: number;
  previewToken?: string;
};

type OpenOrdersRouteData = {
  routeName: RouteName.OpenOrders;
  params?: Params;
};

type OpenOrdersPage = {
  component: PageComponentNames;
  lines: OpenOrdersItem[];
  size: number;
  filter: Filter;
};

export const defaultSize = 10;

const handler: Handler<OpenOrdersRouteData, OpenOrdersPage> = ({ params }, _state$, { api }) => {
  const filter = normalizeFilter(params && params.filter);
  
  if (params?.previewToken) {
    return api.graphApi(loadSystemPageQuery('openOrders')).pipe(
      map(({ pages: { openOrders: page } }) => {
        if (!page)
          return null;

        return {
          page: {
            component: PageComponentNames.OpenOrders,
            ...initPageContent(page),
            lines: [],
            totalCount: 0,
            size: defaultSize,
            filter,
          },
        };
      }),
    );
  }

  const options = createOptions(params, filter);

  return api.graphApi(openOrdersPageQuery, { options }).pipe(
    map(({ pages: { openOrders: page }, openOrder: { listItems } }) => {
      if (!page)
        return null;

      const initializedPage = initPageContent(page);

      const resultPage = {
        component: PageComponentNames.OpenOrders,
        size: defaultSize,
        filter,
        lines: listItems,
        totalCount: listItems.totalCount,
        ...initializedPage,
      };

      return { page: resultPage };
    }),
  );

};

export default handler;

export function normalizeFilter<TFilter extends Filter>(filter?: TFilter): TFilter {
  if (filter && filter.orderDate)
    return filter;

  return { ...filter, orderDate: { from: null }, sortField: AvailableSortingOptions.ByOrderDateInDSC } as TFilter;
}

export function createOptions<TFilter extends Filter>(params?: Params, filter?: TFilter): { page: { index: number; size: number } } & TFilter {
  const index = (params && params.index) || 0;
  const page = { index: 0, size: defaultSize + defaultSize * index };

  return { ...filter, page } as { page: { index: number; size: number } } & TFilter;
}