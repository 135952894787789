import type { User, Abilities } from './types';
import type { AbilityTo } from './constants';
import type {
  UserAction,
  UserAuthenticatedAction,
  UserAbilitiesLoadedAction,
  UserProfileUpdatedAction,
  UserPriceVisibilityChangedAction,
  UserAddExpiredAbilitiesAction,
} from './actions';
import type { OfflineModeChangedAction } from 'behavior/app';
import {
  USER_AUTHENTICATED,
  USER_ABILITIES_LOADED,
  USER_PROFILE_UPDATED,
  LOGIN_FAILED,
  LOGIN_FAILED_RESET,
  USER_LOGIN,
  IMPERSONATION_FAILED,
  IMPERSONATION_FAILED_RESET,
  REPRESENT_CUSTOMER,
  USER_PRICE_VISIBILITY_CHANGED,
  USER_ADD_EXPIRED_ABILITY,
} from './actions';
import { OFFLINE_MODE_CHANGED } from 'behavior/app';
import { createReducer } from 'utils/redux';

type ExpiredAbilities = Readonly<AbilityTo[]>;

type InitialState = Readonly<{
  id: undefined;
  name: undefined;
  email: undefined;
  type: undefined;
  isAuthenticated: undefined;
  shopAccountType: undefined;
  role: undefined;
  pricesInclTax: undefined;
  currencyId: undefined;
  customer: undefined;
  customerType: undefined;
  abilities: Abilities;
  expiredAbilities: ExpiredAbilities;
  initialized: false;
  loginFailed: false;
  impersonationFailed: false;
  hidePrices: false;
  canSeeStockLevel: false;
}>;

type InitializedUserState = Readonly<User & {
  abilities: Abilities;
  expiredAbilities: ExpiredAbilities;
  initialized: true;
  loginFailed: boolean;
  impersonationFailed: boolean;
}>;

const initialState = {
  abilities: {} as Abilities,
  expiredAbilities: [] as ExpiredAbilities,
  initialized: false,
  loginFailed: false,
  impersonationFailed: false,
} as InitialState;

type State = InitialState | InitializedUserState;

type Action = OfflineModeChangedAction | UserAction;

export default createReducer<State, Action>(initialState, {
  [USER_AUTHENTICATED]: onUserAuthenticated,
  [OFFLINE_MODE_CHANGED]: onOfflineModeChanged,
  [USER_ABILITIES_LOADED]: onAbilitiesLoaded,
  [USER_PROFILE_UPDATED]: onUserProfileUpdated,
  [LOGIN_FAILED]: onLoginFailed,
  [LOGIN_FAILED_RESET]: resetLoginFailureStatus,
  [USER_LOGIN]: resetLoginFailureStatus,
  [IMPERSONATION_FAILED]: onImpersonationFailed,
  [IMPERSONATION_FAILED_RESET]: resetFailedImpersonationStatus,
  [REPRESENT_CUSTOMER]: resetFailedImpersonationStatus,
  [USER_PRICE_VISIBILITY_CHANGED]: onPriceVisibilityChanged,
  [USER_ADD_EXPIRED_ABILITY]: onUserAddExpiredAbilities,
});

function onUserAuthenticated(state: State, action: UserAuthenticatedAction): State {
  return {
    ...action.payload,
    initialized: true,
    loginFailed: false,
    impersonationFailed: false,
    abilities: action.payload.abilities || state.abilities,
    expiredAbilities: action.payload.abilities ? [] : Object.keys(state.abilities) as ExpiredAbilities,
  };
}

function onOfflineModeChanged(state: State): State {
  return {
    ...state,
    expiredAbilities: Object.keys(state.abilities) as ExpiredAbilities,
  };
}

function onAbilitiesLoaded(state: State, action: UserAbilitiesLoadedAction): State {
  const loadedKeys = Object.keys(action.payload);
  const newExpiredAbilities = state.expiredAbilities.filter(k => !loadedKeys.includes(k));
  return {
    ...state,
    abilities: {
      ...state.abilities,
      ...action.payload,
    },
    expiredAbilities: newExpiredAbilities.length === state.expiredAbilities.length ? state.expiredAbilities : newExpiredAbilities,
  };
}

function onUserProfileUpdated(state: State, action: UserProfileUpdatedAction): State {
  return {
    ...state as InitializedUserState, ...action.payload,
  };
}

function onLoginFailed(state: State): State {
  return {
    ...state as InitializedUserState, loginFailed: true,
  };
}

function resetLoginFailureStatus(state: State): State {
  return state.loginFailed
    ? { ...state, loginFailed: false }
    : state;
}

function onImpersonationFailed(state: State): State {
  return {
    ...state as InitializedUserState, impersonationFailed: true,
  };
}

function resetFailedImpersonationStatus(state: State): State {
  return state.impersonationFailed
    ? { ...state, impersonationFailed: false }
    : state;
}

function onPriceVisibilityChanged(state: State, action: UserPriceVisibilityChangedAction): State {
  return {
    ...state as InitializedUserState, hidePrices: action.payload,
  };
}

function onUserAddExpiredAbilities(state: State, action: UserAddExpiredAbilitiesAction): State {
  return {
    ...state,
    expiredAbilities: [...state.expiredAbilities, ...action.payload],
  };
}
