import type { 
  ChopItemImportRecord, 
  Line, 
  InvalidItemImportResult, 
  ChopCalculatedData,
  SaveOrderTemplateResult,
  QuickOrderLineInput,
} from './types';

export const CHOP_QUICK_ORDER_PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED = 'CHOP_QUICK_ORDER/PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED' as const;
export const searchProducts = (keywords: string, count: number, ignoreGrouping = false) => ({
  type: CHOP_QUICK_ORDER_PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED,
  payload: { keywords, count, ignoreGrouping },
});

export const CHOP_QUICK_ORDER_PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED = 'CHOP_QUICK_ORDER/PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED' as const;
export const receiveSearchSuggestions = (suggestionsIds: string[]) => ({
  type: CHOP_QUICK_ORDER_PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED,
  payload: { suggestionsIds },
});

export const CHOP_QUICK_ORDER_ADD_ITEM_LINE = 'CHOP_QUICK_ORDER/ADD_ITEM_LINE' as const;
export const addItemLine = (productId: string, lineIndex: number, ignoreOrderable: boolean) => ({
  type: CHOP_QUICK_ORDER_ADD_ITEM_LINE,
  payload: { productId, ignoreOrderable, lineIndex },
});

export const CHOP_QUICK_ORDER_ITEM_LINE_ADDED = 'CHOP_QUICK_ORDER/ITEM_LINE_ADDED' as const;
export const itemLineAdded = (line: Line, lineIndex: number) => ({
  type: CHOP_QUICK_ORDER_ITEM_LINE_ADDED,
  payload: { line, lineIndex },
});

export const CHOP_QUICK_ORDER_ITEM_LINE_REMOVED = 'CHOP_QUICK_ORDER/ITEM_LINE_REMOVED' as const;
export const removeItemLine = (lineIndex: number) => ({
  type: CHOP_QUICK_ORDER_ITEM_LINE_REMOVED,
  payload: lineIndex,
});

export const CHOP_QUICK_ORDER_ALL_ITEM_LINES_REMOVED = 'CHOP_QUICK_ORDER/ALL_ITEM_LINES_REMOVED' as const;
export const removeAllItemLines = () => ({
  type: CHOP_QUICK_ORDER_ALL_ITEM_LINES_REMOVED,
});

export const CHOP_QUICK_ORDER_ITEM_LINES_CLEARED = 'CHOP_QUICK_ORDER/ITEM_LINES_CLEARED' as const;
export const clearItemLines = () => ({
  type: CHOP_QUICK_ORDER_ITEM_LINES_CLEARED,
});

export const CHOP_QUICK_ORDER_PRODUCT_SELECTOR_PRODUCT_CLEARED = 'CHOP_QUICK_ORDER/PRODUCT_SELECTOR_PRODUCT_CLEARED' as const;
export const clearProduct = () => ({
  type: CHOP_QUICK_ORDER_PRODUCT_SELECTOR_PRODUCT_CLEARED,
});

export const CHOP_QUICK_ORDER_CHANGE_TOTAL_LINES_COUNT = 'CHOP_QUICK_ORDER/CHANGE_TOTAL_LINES_COUNT' as const;
export const addNextLinesPage = () => ({
  type: CHOP_QUICK_ORDER_CHANGE_TOTAL_LINES_COUNT,
});

export const CHOP_QUICK_ORDER_IMPORT_PARSING_RESULT_SET = 'CHOP_QUICK_ORDER_IMPORT/PARSING_RESULT/SET' as const;
export const setParsingResult = (records: ChopItemImportRecord[], recordErrors: InvalidItemImportResult[]) => ({
  type: CHOP_QUICK_ORDER_IMPORT_PARSING_RESULT_SET,
  payload: { records, recordErrors },
});

export const CHOP_QUICK_ORDER_IMPORT_PARSING_RESULT_CLEAR = 'CHOP_QUICK_ORDER_IMPORT/PARSING_RESULT/CLEAR' as const;
export const clearParsingResult = () => ({
  type: CHOP_QUICK_ORDER_IMPORT_PARSING_RESULT_CLEAR,
});

export const CHOP_QUICK_ORDER_IMPORT_ERROR_SET = 'CHOP_QUICK_ORDER_IMPORT/ERROR/SET' as const;
export const setError = (error: string) => ({
  type: CHOP_QUICK_ORDER_IMPORT_ERROR_SET,
  payload: { error },
});

export const CHOP_QUICK_ORDER_IMPORT_UPLOAD_RECORDS = 'CHOP_QUICK_ORDER_IMPORT/UPLOAD_RECORDS' as const;
export const uploadRecords = (records: ChopItemImportRecord[]) => ({
  type: CHOP_QUICK_ORDER_IMPORT_UPLOAD_RECORDS,
  payload: { records },
});

export const CHOP_QUICK_ORDER_IMPORT_RECORDS_UPLOADED = 'CHOP_QUICK_ORDER_IMPORT/RECORDS_UPLOADED' as const;
export const onRecordsUploaded = (lines: Line[]) => ({
  type: CHOP_QUICK_ORDER_IMPORT_RECORDS_UPLOADED,
  payload: { lines },
});

export const CHOP_QUICK_ORDER_APPLY_CALCULATED_DATA = 'CHOP_QUICK_ORDER_IMPORT/APPLY_CALCULATED_DATA' as const;
export const applyCalculatedData = (calculatedData: ChopCalculatedData[]) => ({
  type: CHOP_QUICK_ORDER_APPLY_CALCULATED_DATA,
  payload: { calculatedData },
});

export const CHOP_QUICK_ORDER_TEMPLATE_SAVE = 'CHOP_QUICK_ORDER/EMPLATE_SAVE' as const;
export const saveOrderTemplate = (name: string, lines: QuickOrderLineInput[]) => ({
  type: CHOP_QUICK_ORDER_TEMPLATE_SAVE,
  payload: { name, lines },
});

export const CHOP_QUICK_ORDER_TEMPLATE_SAVED = 'CHOP_QUICK_ORDER/TEMPLATE_SAVED' as const;
export const orderTemplateSavingResultReceived = (savingResult: SaveOrderTemplateResult) => ({
  type: CHOP_QUICK_ORDER_TEMPLATE_SAVED,
  payload: { savingResult },
});

export const CHOP_QUICK_ORDER_UPDATE_LINES_DATA = 'CHOP_QUICK_ORDER/UPDATE_LINES_DATA' as const;
export const updateLinesData = () => ({
  type: CHOP_QUICK_ORDER_UPDATE_LINES_DATA,
});

export const CHOP_QUICK_ORDER_LINES_DATA_UPDATED = 'CHOP_QUICK_ORDER/LINES_DATA_UPDATE' as const;
export const linesDataUpdated = (lines: Line[]) => ({
  type: CHOP_QUICK_ORDER_LINES_DATA_UPDATED,
  payload: { lines },
});

export type ReceiveSearchSuggestionsAction = ReturnType<typeof receiveSearchSuggestions>;
export type ReceiveProductAction = ReturnType<typeof itemLineAdded>;
export type RecordsUploadedAction = ReturnType<typeof onRecordsUploaded>;
export type RemoveProductAction = ReturnType<typeof removeItemLine>;
export type SetParsingResultProductAction = ReturnType<typeof setParsingResult>;
export type SetErrorProductAction = ReturnType<typeof setError>;
export type ApplyCalculatedDataAction = ReturnType<typeof applyCalculatedData>;
export type ChangeTotalLinesCountAction = ReturnType<typeof addNextLinesPage>;
export type OrderTempalteSavedAction = ReturnType<typeof orderTemplateSavingResultReceived>;
export type LinesDataUpdatedAction = ReturnType<typeof linesDataUpdated>;

export type ChopProductPageAction = ReturnType<
  | typeof searchProducts
  | typeof addItemLine
  | typeof uploadRecords
  | typeof clearProduct
  | typeof clearItemLines
  | typeof updateLinesData
  | typeof setError
  | typeof onRecordsUploaded
  | typeof setParsingResult
  | typeof clearParsingResult
  | typeof saveOrderTemplate
  | typeof removeAllItemLines
>
  | ReceiveSearchSuggestionsAction
  | ReceiveProductAction
  | RemoveProductAction
  | SetParsingResultProductAction
  | SetErrorProductAction
  | ChangeTotalLinesCountAction
  | RecordsUploadedAction
  | ApplyCalculatedDataAction
  | OrderTempalteSavedAction
  | LinesDataUpdatedAction;
