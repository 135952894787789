import type { OpenOrdersList, OpenOrdersLoadOptionsInput, OpenOrdersFilterOptionInput} from './types';

export const OPEN_ORDERS_REQUESTED = 'OPEN_ORDERS_REQUESTED';
export const loadOpenOrders = (options: OpenOrdersLoadOptionsInput) => ({
  type: OPEN_ORDERS_REQUESTED,
  payload: { options },
});

export const OPEN_ORDERS_FILE_DOWNLOADED = 'OPEN_ORDERS_FILE_DOWNLOADED';
export const openOrdersFildeDownloaded = () => ({
    type: OPEN_ORDERS_FILE_DOWNLOADED,
    payload: { },
});

export const OPEN_ORDERS_FILTER_UPDATED = 'OPEN_ORDERS_FILTER_UPDATED';
export const openOrdersFilterUpdated = (filter: OpenOrdersFilterOptionInput) => ({
    type: OPEN_ORDERS_FILTER_UPDATED,
    payload: {filter},
});

export const OPEN_ORDERS_RECEIVED = 'OPEN_ORDERS_RECEIVED';
export const openOrdersReceived = (listItems: OpenOrdersList) => ({
  type: OPEN_ORDERS_RECEIVED,
  payload: { listItems },
});

export type openOrdersAction = ReturnType<
    | typeof openOrdersReceived
    >;

export type openOrdersFilterUpdated = ReturnType<
    | typeof openOrdersFilterUpdated
>;