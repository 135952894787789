import type { Handler } from '../types';
import { PageComponentNames } from 'behavior/pages';
import { RouteName } from 'routes';
import { map } from 'rxjs/operators';
import type { MyAddressBookPage, Addressbook } from './types';
import { ContentSystemPageData, initContentSystemPage } from '../system';
import type { EntityTemplateField } from 'behavior/entityTemplates';
import { myAddressBookPageQuery } from './queries';

const handler: Handler<MyAddressBookRouteData, MyAddressBookPage> = (_routeData, _state$, { api }) =>
  api.graphApi<MyAddressBookPageResponse>(myAddressBookPageQuery).pipe(
    map(({ pages: { myAddressBook }, viewer: { customer }, profile }) => ({
      page: {
        ...initContentSystemPage(myAddressBook),
        component: PageComponentNames.MyAddressBook as const,
        customer,
        templateFields: profile.myAddressFields,
      },
    })),
  );

export default handler;

type MyAddressBookRouteData = {
  routeName: RouteName.MyAddressBook;
};

type MyAddressBookPageResponse = {
  pages: {
    myAddressBook: ContentSystemPageData;
  };
  viewer: {
    customer: Addressbook;
  };
  profile: {
    myAddressFields: EntityTemplateField[];
  };
};
