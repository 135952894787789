import { retryWithToast } from 'behavior/errorHandling';
import { skipIfPreview } from 'behavior/preview';
import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { switchMap, mergeMap } from 'rxjs/operators';
import { CHECKOUT_BASKETL_INES_UPDATE, refreshData } from './actions';
import { navigateOnIncorrect } from './helpers';
import { getUpdateBasketMutation } from './queries';
import { requestBasketSummary } from 'behavior/basket';

export default function createEpic(waitForSubmit) {
  return function (action$, state$, { api, logger }) {
    const isQuote = () => state$.value.page.info?.isQuote || false;
    const isPromotion = () => !!state$.value.page.info?.quote;

    const updateBasketLines$ = action$.pipe(
      ofType(CHECKOUT_BASKETL_INES_UPDATE),
      skipIfPreview(state$),
      switchMap(({ payload: { modified } }) => waitForSubmit(() => {
        return api.graphApi(getUpdateBasketMutation(isPromotion()), {
          data: { modified },
          asQuote: isQuote(),
          maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
        }).pipe(
          mergeMap(({ checkout }) => {
            if (checkout) {
              const { success } = checkout.orderOverview.update;
              if (success)
                return of(refreshData(), requestBasketSummary(true));
            }

            return of(navigateOnIncorrect(state$.value.page.info));
          }),
          retryWithToast(action$, logger),
        );
      })),
    );

    return merge(updateBasketLines$);
  };
}