import type { Cookies } from '../cookies';
import type { ToolsStorage } from '../tools';
import type { GraphApiResponse } from './types';
import { tap, pluck, share, retryWhen } from 'rxjs/operators';
import { identity, Observable, Observer } from 'rxjs';
import { createAuthTokenStorage } from './authentication';
import { createRequestTracker } from './tracking';
import { createLocalizationStorage } from './localization';
import { createConnectionTracker } from './connectionTracker';
import retryRequestsPolicy from './retryRequests';
import { createErrorCodesTracker } from './errorCodesTracker';
import { createExtensionsTracker } from './extensionsTracker';
import { logger } from '../logs';
import { isAbsoluteUrl } from 'utils/url';
import { FileGroup } from './files';

type Fetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>;

type OpenOrdersRequestBody = {
  orderNo: string | null;
  itemNo: string | null;
  sortField: string | null;
  from: string | null;
  to: string | null;
};

type ApiParams = {
  path: string;
  headers?: Record<string, string>;
  fetch: Fetch;
  fetchAgent?: unknown;
  multiTab?: boolean;
  token?: string;
  protocol: string;
  cookies: Cookies;
  toolsStorage: ToolsStorage;
};

type GraphApiOptions = {
  authToken?: string | null;
  retries?: number;
  useCookies?: boolean;
  files?: FileList | File[] | FileGroup[];
  useStableApi?: boolean;
  ignoreStatuses?: boolean;
};

type FetchOptions = Omit<RequestInit, 'headers'> & {
  headers?: Record<string, string>;
};

export type Api = ReturnType<typeof createApi>;

export function createApi(options: ApiParams) {
  const requestTracker = createRequestTracker();
  const customHeaders: Record<string, string> = { ...options.headers };
  const graphApiPath = options.path;
  const stableGraphApiPath = graphApiPath + '/stable';

  const sanaFetch = makeReactiveFetch(options.fetch, options.fetchAgent);

  const tokenStorage = createAuthTokenStorage({
    broadcast: options.multiTab || false,
    initialToken: options.token,
  });

  const localizationStorage = createLocalizationStorage({
    cookies: options.cookies,
    maxAge: 31536000, // 365 days measured in seconds.
  });

  const toolsStorage = options.toolsStorage;

  const { connection$, trackConnection } = createConnectionTracker(options.fetch);
  const { errors$, trackErrors } = createErrorCodesTracker([401, 503]);
  const { extensions$, trackExtensions } = createExtensionsTracker();
  const emptyOptions = {};

  function graph<T = any>(
    query: string,
    variables?: unknown,
    options: GraphApiOptions = emptyOptions,
  ): Observable<T> {

    const authToken = 'authToken' in options ? options.authToken : tokenStorage.getValue();
    const languageId = localizationStorage.getValue();
    const toolsEnabled = toolsStorage.anyToolEnabled();
    const { files = null, retries = 2, useCookies = false, ignoreStatuses } = options;

    const credentials = useCookies || !authToken || toolsEnabled ? 'include' : 'omit';
    const headers: Record<string, string> = { ...customHeaders };

    addAuthHeader(headers, authToken);
    if (languageId)
      headers['X-LanguageId'] = languageId;

    let body;

    if (files) {
      body = new FormData();
      body.append('query', query);
      body.append('variables', JSON.stringify(variables));

      for (const item of files) {
        if (item instanceof FileGroup) {
          for (const file of item.files)
            body.append(item.name, file, file.name);
        } else {
          body.append('files[]', item, item.name);
        }
      }
    } else {
      headers['Content-Type'] = 'application/json; charset=UTF-8';
      body = JSON.stringify({ query, variables });
    }

    const path = options.useStableApi ? stableGraphApiPath : graphApiPath;
    return sanaFetch<GraphApiResponse>(path, {
      headers,
      credentials,
      method: 'POST',
      body,
    }).pipe(
      share(),
      retryWhen(retryRequestsPolicy(retries)),
      trackConnection,
      tap(logGraphQLErrors),
      trackExtensions,
      pluck('data'),
      requestTracker.trackObservable,
      ignoreStatuses ? identity : trackErrors,
    );
  }

  return {
    setLanguage: localizationStorage.saveValue,
    setAuthToken: (token: string | null, broadcast = true) => tokenStorage.saveValue(token, broadcast),
    headers: {
      add: (name: string, value: string) => customHeaders[name] = value,
      delete: (name: string) => void (delete customHeaders[name]),
    },
    authChanges$: tokenStorage.new$,
    connection$,
    errors$,
    extensions$,
    graphApi: graph,
    fetchOpenOrders(body: OpenOrdersRequestBody): Promise<Response> {
      const headers: Record<string, string> = { 'X-Supports-Response-Extensions': 'true' };
      addStandardHeaders(headers);

      return fetch('/openorders/download', {
        headers,
        method: 'POST',
        body: JSON.stringify(body),
      });
    },
    fetch<T = any>(url: string, fetchOptions: FetchOptions = {}) {
      const headers: Record<string, string> = { ...fetchOptions.headers };
      const isLocalPath = !isAbsoluteUrl(url);

      let fetch: ReturnType<typeof makeReactiveFetch>;

      if (isLocalPath) {
        addStandardHeaders(headers);
        fetch = sanaFetch;
      } else {
        // In SSR sanaFetch is constructed with HTTP agent with keepAlive:true.
        // This agent should not be used for HTTPS requests + there is no need to keep connection with external origins.
        // Pass undefined agent, so that fetch will use http.globalAgent/https.globalAgent automatically.
        fetch = options.fetchAgent ? makeReactiveFetch(options.fetch) : sanaFetch;
      }

      return fetch<T>(url, {
        method: 'GET',
        ...fetchOptions,
        headers,
        credentials: isLocalPath ? 'same-origin' : 'omit',
      }).pipe(
        requestTracker.trackObservable,
      );
    },
    isRunning() {
      return requestTracker.hasInProgress();
    },
    isReady$: requestTracker.isReady$,
    trackObservable<T>(observable: Observable<T>) {
      return requestTracker.trackObservable(observable);
    },
  };

  function addStandardHeaders(headers: Record<string, string>) {
    headers['X-UseAuthCookie'] = 'true';
    const languageId = localizationStorage.getValue();
    if (languageId) {
      headers['X-LanguageId'] = languageId;
    }
  }
}

function makeReactiveFetch(fetch: Fetch, agent?: unknown) {
  return <T>(url: string, options?: RequestInit): Observable<T> => {
    return new Observable<T>((observer: Observer<T>) => {
      const abortController = typeof AbortController === 'function' ? new AbortController() : null;
      const fetchOptions = {
        ...options,
        signal: abortController && abortController.signal,
        agent,
      };

      const handleError = (e: any) => {
        if (e.name === 'AbortError')
          observer.complete();
        else
          observer.error(e);
      };
      fetch(url, fetchOptions)
        .then(parseFetchResponse, handleError)
        .then(r => {
          observer.next(r);
          observer.complete();
        }, handleError);
      return () => {
        abortController && abortController.abort();
      };
    });
  };
}

function parseFetchResponse(response: Response) {
  if (!response.ok)
    return response.text().then(r => Promise.reject({ status: response.status, response: tryParseJson(r) }));

  const contentType = response.headers.get('Content-Type') || '';
  if (contentType.startsWith('application/json'))
    return response.json();
  else
    return response.text();
}

function addAuthHeader(headers: Record<string, string>, authToken: string | null | undefined) {
  if (authToken === undefined)
    headers['X-UseAuthCookie'] = 'true';
  else if (authToken)
    headers.Authorization = 'Bearer ' + authToken;
}

function logGraphQLErrors(response: GraphApiResponse) {
  if (response.errors)
    response.errors.forEach(e => logger.warn(e.message));
}

function tryParseJson(text: string) {
  try {
    return text && text.length ? JSON.parse(text) : text;
  }
  catch {
    return text;
  }
}
