import {
    OPEN_ORDERS_RECEIVED,
    OPEN_ORDERS_FILE_DOWNLOADED,
    OPEN_ORDERS_FILTER_UPDATED,
} from 'behavior/pages/openOrders/actions';
import type { State} from './types';
import {
    openOrdersAction,
    openOrdersFilterUpdated,
} from './actions';

const initialState: State = {
    listItems: null,
    itemsRecievedForDownload: false,
};


export function onOpenOrdersReceivedReducer(state: State, action: openOrdersAction) {

  if (action.type === OPEN_ORDERS_RECEIVED) {
    return {
      ...state,
        listItems: action.payload.listItems.openOrders.listItems,
        itemsRecievedForDownload: true,
  };
  }


    if (action.type === OPEN_ORDERS_FILE_DOWNLOADED) {
        return {
            ...state,
            itemsRecievedForDownload: false,
        };
    }

    return state;
}


export function onOpenOrdersFilterUpdatedReducer(state: State, action: openOrdersFilterUpdated) {

   
    if (action.type === OPEN_ORDERS_FILTER_UPDATED) {
        return {
            ...state,
            filter: action.payload.filter,
        };
    }


    return state;
}