import type { Epic } from 'behavior/types';
import type { BaseProduct, Line, Product, SaveOrderTemplateResult } from './types';
import { ofType } from 'redux-observable';
import { EMPTY, merge, of } from 'rxjs';
import { mergeMap, pluck, map, switchMap, startWith, takeUntil } from 'rxjs/operators';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { routesBuilder } from 'routes';
import {
  setParsingResult,
  ChopProductPageAction,
  CHOP_QUICK_ORDER_PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED,
  CHOP_QUICK_ORDER_ADD_ITEM_LINE,
  receiveSearchSuggestions,
  itemLineAdded,
  CHOP_QUICK_ORDER_IMPORT_UPLOAD_RECORDS,
  onRecordsUploaded,
  clearParsingResult,
  CHOP_QUICK_ORDER_TEMPLATE_SAVE,
  orderTemplateSavingResultReceived,
  CHOP_QUICK_ORDER_UPDATE_LINES_DATA,
  CHOP_QUICK_ORDER_ALL_ITEM_LINES_REMOVED,
  linesDataUpdated,
} from './actions';
import { productSearchSuggestionsQuery, productQuery, saveTemplateMutation, baseProductsDataQuery, loadCalculatedDataQuery } from './queries';
import { processRecords } from './helpers';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { LOCATION_CHANGED } from 'behavior/events';
import { iEquals } from 'utils/helpers';
import { NonReversibleMoulding, RebateExact, ReversibleLongSideup } from './constants';

type ProductSearchSuggestionsResponse = {
  catalog: {
    quickChopSearch: {
      productsIds: string[];
    };
  };
};

type ProductQueryResponse = {
  catalog: {
    chopProducts: {
      products: Product[];
    };
  };
};

type ProductsQueryResponse = {
  catalog: {
    mainItems: {
      products: Product[];
    };
    chopItems: {
      products: Product[];
    };
  };
};

type BaseProductsQueryResponse = {
  catalog: {
    chopProducts: {
      products: BaseProduct[];
    };
  };
};

const setLoading = setLoadingIndicator();
const unsetLoading = unsetLoadingIndicator();

const epic: Epic<ChopProductPageAction> = (action$, state$, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
  const onDeleteAll$ = action$.pipe(ofType(CHOP_QUICK_ORDER_ALL_ITEM_LINES_REMOVED));

  const searchProducts$ = action$.pipe(
    ofType(CHOP_QUICK_ORDER_PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED),
    pluck('payload'),
    mergeMap(({ keywords, count }) => api.graphApi<ProductSearchSuggestionsResponse>(
      productSearchSuggestionsQuery, {
      options: { keywords, count },
    }).pipe(
      pluck('catalog', 'quickChopSearch', 'productsIds'),
      map(receiveSearchSuggestions),
      retryWithToast(action$, logger),
    )),
  );

  const requestProduct$ = action$.pipe(
    ofType(CHOP_QUICK_ORDER_ADD_ITEM_LINE),
    pluck('payload'),
    mergeMap(payload => {
      const { productId, lineIndex } = payload;
      const params = {
        ids: [ productId ],
      };
      return api.graphApi<ProductQueryResponse>(productQuery, params).pipe(
        pluck('catalog', 'chopProducts', 'products'),
        map(products => {
          const product = products[0];
          product.routeData = routesBuilder.forProduct(product.id);
          if (product.isChopItem && product.mainItem) {
            product.image = product.mainItem.image;
            product.reversible = product.mainItem.reversible;
          }
          
          const { isChopItem, chopItemMinLength, chopItemMinWidth, uom, reversible } = product;
          const line : Line = { 
            quantity: uom?.minimumQuantity || 1, 
            product, 
          };

          if (isChopItem) {
            line.length = chopItemMinLength; 
            line.width = chopItemMinWidth; 
            line.serviceType = RebateExact;
            line.mouldingType = reversible ? ReversibleLongSideup : NonReversibleMoulding;
          }

          return itemLineAdded(line, lineIndex);
        }),
        takeUntil(onDeleteAll$),
        retryWithToast(action$, logger),
      );
    }),
  );

  const uploadRecords$ = action$.pipe(
    ofType(CHOP_QUICK_ORDER_IMPORT_UPLOAD_RECORDS),
    pluck('payload'),
    switchMap(payload => {
      const { records } = payload;
      const params = {
        ids: records.map(record => record.productId),
      };
      return api.graphApi<BaseProductsQueryResponse>(baseProductsDataQuery, params).pipe(
        pluck('catalog', 'chopProducts', 'products'),
        switchMap(products => {
          const updatedRecords = records.map(record => {
            const product = products.find(product => iEquals(record.productId, product.id));
            if (!product || product.isChopItem === record.isChopItem)
              return record;
            
            return {
              ...record, 
              isChopItem: !product.isChopItem,
              productId: product.isChopItem
                ? product.mainItemId!
                : product.chopItemNo!, 
            };
          });

          const params = {
            chopIds: updatedRecords.filter(record => record.isChopItem).map(record => record.productId), 
            mainIds: updatedRecords.filter(record => !record.isChopItem).map(record => record.productId), 
          };

          return api.graphApi<ProductsQueryResponse>(loadCalculatedDataQuery, params).pipe(
            pluck('catalog'),
            mergeMap(catalog => {
              const { mainItems, chopItems } = catalog;
              const products = [...mainItems.products, ...chopItems.products ];
              const processingResult = processRecords(updatedRecords, products);
              if (processingResult.invalidRecords.length)
                return of(setParsingResult([], processingResult.invalidRecords), unsetLoading);
    
              return of(onRecordsUploaded(processingResult.lines), clearParsingResult(), unsetLoading);
            }),
            retryWithToast(action$, logger),
            catchApiErrorWithToast(['INVALID_INPUT'], of(unsetLoading)),
            startWith(setLoading),
          );
        }),
        retryWithToast(action$, logger),
        catchApiErrorWithToast(['INVALID_INPUT'], of(unsetLoading)),
        startWith(setLoading),
      );
    }),
  );

  const $saveTemplate = action$.pipe(
    ofType(CHOP_QUICK_ORDER_TEMPLATE_SAVE),
    mergeMap(action => api.graphApi<SaveOrderTemplateResponse>(saveTemplateMutation, { input: action.payload }).pipe(
      mergeMap(({ orderTemplates }) => of(
        orderTemplateSavingResultReceived(orderTemplates && orderTemplates.saveQuickOrder),
        unsetLoading,
      )),
      takeUntil(locationChanged$),
      startWith(setLoading),
      retryWithToast(action$, logger),
    )),
  );

  const $updateLinesData = action$.pipe(
    ofType(CHOP_QUICK_ORDER_UPDATE_LINES_DATA),
    pluck('payload'),
    switchMap(_ => {
      const itemLines = state$.value.chopQuickOrder.itemLines.filter(line => !!line);
      if (!itemLines.length)
        return EMPTY;

      const params = {
        chopIds: itemLines.filter(line => line!.product.isChopItem).map(line => line!.product.id), 
        mainIds: itemLines.filter(line => !line!.product.isChopItem).map(line => line!.product.id), 
      };

      return api.graphApi<ProductsQueryResponse>(loadCalculatedDataQuery, params).pipe(
        pluck('catalog'),
        mergeMap(catalog => {
          const updatedLines = [];
          const { mainItems, chopItems } = catalog;
          const products = [...mainItems.products, ...chopItems.products ];
          const existingLines = state$.value.chopQuickOrder.itemLines.filter(line => !!line);
          for (const product of products) {
            product.routeData = routesBuilder.forProduct(product.id);
            if (product.isChopItem && product.mainItem) {
              product.image = product.mainItem.image;
              product.reversible = product.mainItem.reversible;
            }
          }

          for (const line of existingLines) {
            const product = products.find(product => iEquals(product.id, line!.product.id));
            if (!product)
              continue;

            updatedLines.push({
              ...line!,
              product,
            });
          }
          
          return of(linesDataUpdated(updatedLines), unsetLoading);
        }),
        retryWithToast(action$, logger),
        catchApiErrorWithToast(['INVALID_INPUT'], of(unsetLoading)),
        startWith(setLoading),
      );
    }),
  );

  return merge(searchProducts$, requestProduct$, uploadRecords$, $saveTemplate, $updateLinesData);
};

export default epic;

type SaveOrderTemplateResponse = {
  orderTemplates: {
    saveQuickOrder: SaveOrderTemplateResult;
  } | null;
};
