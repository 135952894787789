import { SimpleText } from 'components/sanaText';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { userChangePriceVisibility } from 'behavior/user';

const ShowPricesLink = ({ ...attributes }) => {
  const dispatch = useDispatch();
  const { isAuthenticated, hidePrices } = useSelector(state => ({
    isAuthenticated: state.user.isAuthenticated,
    hidePrices: state.user.hidePrices,
  }), shallowEqual);

  if (!isAuthenticated)
    return null;

  const handleClick = () => {
    dispatch(userChangePriceVisibility());
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <a {...attributes} role="button" tabIndex={0} onClick={handleClick}>
      <SimpleText textKey={hidePrices ? 'ShowPrices' : 'HidePrices'} />
    </a>
  );
};

export default ShowPricesLink;
