import { SimpleText } from 'components/sanaText';
import Link from './base/Link';
import { useSelector } from 'react-redux';
import { Settings } from 'behavior/settings';
import { withAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user';
import { AbilityState } from 'behavior/user/constants';

type Props = {
  abilities: [AbilityState];
  className: string;
};

const QuickOrderPageLink = ({ abilities: [orderProductsAbility], className }: Props) => {
  const isAuthenticated = useSelector(s => s.user.isAuthenticated);
  const quickOrderPage = useSelector(s => s.settings.loaded ? (s.settings as Settings).quickOrderPage : null);
  
  if (!isAuthenticated || !quickOrderPage || orderProductsAbility !== AbilityState.Available)
    return null;

  return (
    <Link to={quickOrderPage.to} url={quickOrderPage.url} className={className}>
      <SimpleText textKey="QuickOrder" />
    </Link>
  );
};

export default withAbilities(QuickOrderPageLink, [AbilityTo.OrderProducts]);
