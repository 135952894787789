import loadable from '@loadable/component';

export default {
  PdpVolumePrices: loadable(() => import(/*webpackChunkName: "pdp"*/ './volumePrices')),
  PdpSpecifications: loadable(() => import(/*webpackChunkName:"pdp"*/'./specifications')),
  PdpAttachments: loadable(() => import(/*webpackChunkName:"pdp"*/ './attachments')),
  PdpAddToWishlist: loadable(() => import(/*webpackChunkName:"pdp"*/'./addToWishlist')),
  PdpQuantityBox: loadable(() => import(/*webpackChunkName:"pdp"*/'./quantityBox')),
  PdpAddToCart: loadable(() => import(/*webpackChunkName:"pdp"*/'./addToCart')),
  PdpBomComponents: loadable(() => import(/*webpackChunkName:"pdp"*/'./bomComponents')),
  PdpRelatedProducts: loadable(() => import(/*webpackChunkName:"pdp"*/'./relatedProducts')),
  PdpDiscounts: loadable(() => import(/*webpackChunkName:"pdp"*/'./discounts')),
  PdpWarehouse: loadable(() => import(/*webpackChunkName:"pdp"*/'./warehouse')), /*Ticket 187114: [SCA] 3.6.Show stocks from different locations*/
  PdpStockIndicator: loadable(() => import(/*webpackChunkName:"pdp"*/'./stockIndicator')),
  PdpPrice: loadable(() => import(/*webpackChunkName:"pdp"*/'./price')),
  PdpNoPriceMessage: loadable(() => import(/*webpackChunkName:"pdp"*/'./noPriceProductMessage')), /*Ticket 187119: [SCA] 3.11.Machinery and crate glasses without prices and orderability*/
  PdpChopBackOrder: loadable(() => import(/*webpackChunkName:"pdp"*/'./chopBackOrder')),
  PdpReviews: loadable(() => import(/*webpackChunkName:"pdp"*/'./reviews')),
  PdpReviewsSummary: loadable(() => import(/*webpackChunkName:"pdp"*/'./reviewsSummary')),
  PdpChopOrderCart: loadable(() => import(/*webpackChunkName:"pdp"*/'./chopOrderCart')), /*Ticket 187106: [SCA] 3.1.Allow chop orders for applicable products*/
  PdpDescription: loadable(() => import(/*webpackChunkName:"pdp"*/'./description')),
  PdpShortDescription: loadable(() => import(/*webpackChunkName:"pdp"*/'./shortDescription')),
  PdpUomSelector: loadable(() => import(/*webpackChunkName:"pdp"*/'./uomSelector')),
  PdpVariantDropdowns: loadable(() => import(/*webpackChunkName:"pdp"*/'./variantDropdowns')),
  PdpSalesAgreementSelector: loadable(() => import(/*webpackChunkName:"pdp"*/'./agreementSelector')),
  PdpNonOrderableMessage: loadable(() => import(/*webpackChunkName:"pdp"*/'./nonOrderableMessage')),
  PdpItemNo: loadable(() => import(/*webpackChunkName:"pdp"*/'./itemNo')),
  PdpUomAndTaxIndicator: loadable(() => import(/*webpackChunkName:"pdp"*/'./uomAndTaxIndicator')),
  PdpCompareSelector: loadable(() => import(/*webpackChunkName:"pdp"*/'./compareSelector')),
  PdpRetailOffers: loadable(() => import(/*webpackChunkName:"pdp"*/'./retailOffers')),
  PdpPriceInfoExtraFields: loadable(() => import(/*webpackChunkName:"pdp"*/'./priceInfoExtraFields')),
  PdpProductGroupLink: loadable(() => import(/*webpackChunkName:"pdp"*/'./productGroupLink')),
  PdpBoxQuantity: loadable(() => import(/*webpackChunkName:"pdp"*/'./boxQuantity')),
  PdpBackorderMessage: loadable(() => import(/*webpackChunkName:"pdp"*/'./backorderMessage')),
};
