import {
  type AccountDetailsAction,
  ACCOUNT_DETAILS_INFO_RECEIVED,
} from './actions';

export const initialState = {};

export default function reducer(state = initialState, action: AccountDetailsAction) {
  switch (action.type) {
    case ACCOUNT_DETAILS_INFO_RECEIVED:
      return { ...state, accountDetails: action.payload.accountDetails };
    default:
      return state;
  }
}
