import { readFileText, checkFileExtension } from './helpers';

export const enum CsvErrorCode {
  FileType = 'FileType',
  HeaderRowFormat = 'HeaderRowFormat',
  InvalidFile = 'InvalidFile',
}

type ParseResult<R, ER> = {
  records: R[];
  recordErrors: Array<{
    errorCodes: string[];
    record: ER;
  }>;
};

type InvalidRowRarseResult<T> = {
  errorCodes: string[];
  record: T;
};

type RowParseResult<T> = {
  record: T;
};

type RowParser<R, ER> = (columnsMap: Record<string, string>) => RowParseResult<R> | InvalidRowRarseResult<ER>;

export async function parseCsvRecords<R, ER>(file: File, columnNames: string[], parseRow: RowParser<R, ER>, columnsToValidate?: number, delimiter?: string): Promise<ParseResult<R, ER>> {
  if (!checkFileExtension(file, 'csv'))
    throw CsvErrorCode.FileType;

  const fileContent = await readFileText(file);
  const rows = csvToArray(fileContent, delimiter);
  const headerValues = columnsToValidate
    ? rows[0].slice(0, columnsToValidate)
    : rows[0];

  if (columnNames.some(key => !headerValues.includes(key)))
    throw CsvErrorCode.HeaderRowFormat;

  if (rows.length <= 1)
    throw CsvErrorCode.InvalidFile;

  const columnToIndexMap = columnNames.reduce((accumulator, key) => {
    accumulator[key] = headerValues.indexOf(key);
    return accumulator;
  }, {} as Record<string, number>);

  const result: ParseResult<R, ER> = {
    records: [],
    recordErrors: [],
  };

  for (let index = 1; index < rows.length; index++) {
    const values = rows[index];
    const columnsMap = Object.entries(columnToIndexMap).reduce((accumulator, [key, index]) => {
      accumulator[key] = values[index];
      return accumulator;
    }, {} as Record<string, string>);

    const parseRowResult = parseRow(columnsMap);

    if (isInvalidRow(parseRowResult))
      result.recordErrors.push(parseRowResult);
    else
      result.records.push(parseRowResult.record);
  }

  return result;
}

function isInvalidRow<R, ER>(result: InvalidRowRarseResult<ER> | RowParseResult<R>): result is InvalidRowRarseResult<ER> {
  return 'errorCodes' in result;
}

export function csvToArray(fileContent: string, delimiter = ',') {
  const preparedDelimiter = mapSpecialDelimiter(delimiter);
  const pattern = new RegExp(
    // Delimiters.
    '(' + preparedDelimiter + '|\r?\n|\r|^)' +
    // Quoted fields.
    '(?:"([^"]*(?:""[^"]*)*)"|' +
    // Standard fields.
    '([^"' + preparedDelimiter + '\r\n]*))',
    'gi',
  );

  const result: string[][] = [[]];

  while (true) {
    const matches = pattern.exec(fileContent);
    if (!matches)
      break;

    const matchedDelimiter = matches[1];
    const matchedValue = matches[2] !== undefined ? matches[2].replace(/""/g, '"') : matches[3];

    if (matchedDelimiter.length && matchedDelimiter !== delimiter) {
      if (!matchedValue)
        break;

      result.push([]);
    }

    result[result.length - 1].push(matchedValue.trim());
  }
  return result;
}

function mapSpecialDelimiter(delimiter: string) {
  switch(delimiter) {
    case '^':
      return '\\^';
    case '|':
      return '\\|';
    default:
      return delimiter;
  }
}