import { EntityTemplateField } from 'behavior/entityTemplates';
import { createReducer } from 'utils/redux';
import { PageComponentNames } from '../componentNames';
import { ShippingAddressDeletedAction, SHIPPING_ADDRESS_DELETED } from './actions';

type State = {
  component: PageComponentNames.MyAddressBook;
  customer: {
    billingAddress: {
      formatted: any | null;
      isPrimary: boolean | null;
    };
    shippingAddresses: {
      id: any | null;
      formatted: any | null;
    }[];
  };
  templateFields: EntityTemplateField[];
};

export default createReducer<State, ShippingAddressDeletedAction>(null as unknown as State, {
  [SHIPPING_ADDRESS_DELETED]: onShippingAddressDelete,
});

function onShippingAddressDelete(state: State, action: ShippingAddressDeletedAction): State {
  return {
    ...state,
    customer: {
      ...state.customer,
      shippingAddresses: state.customer.shippingAddresses.filter(a => a.id !== action.payload.id),
    },
  };
}