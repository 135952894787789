import { of } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { RouteName } from 'routes';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { initContentSystemPage, loadContentSystemPageQuery } from 'behavior/pages/system';
import { CheckoutProcessNames } from './constants';
import { orderCancelledQuery } from './queries';
import type { Handler } from '../types';
import type { ContentSystemPage, ContentSystemPageData } from '../system/content';
import type { CancelledTransaction } from './types';

//Ticket 187109: [SCA] 3.3.Order placement with chop products
const handler: Handler<OrderCancelledRouteData, OrderCancelledPage> = (routeData, _state$, { api }) => {
  const { params: { transactionId, previewToken } } = routeData;

  if (previewToken) {
    return api.graphApi<OrderCancelledPreviewResponse>(loadContentSystemPageQuery('orderCancelled')).pipe(
      map(({ pages: { orderCancelled } }) => ({
        page: {
          ...initContentSystemPage(orderCancelled),
          component: PageComponentNames.OrderCancelled as const,
          transaction: {
            id: '',
            cancelled: true as const,
            isPaymentError: false as const,
            failed: false as const,
              document: { id: '', documentId: '', chopOrderNo: '' },
            checkoutProcessName: CheckoutProcessNames.Order,
          },
        },
      })),
    );
  }

  if (!transactionId)
    return of(null);

  return api.graphApi<OrderCancelledPageResponse>(orderCancelledQuery, { id: transactionId }).pipe(
    mergeMap(({ pages: { orderCancelled }, paymentTransaction: transaction }) => {
      if (transaction == null)
        return of(null);

      return of({
        page: {
          ...initContentSystemPage(orderCancelled),
          component: PageComponentNames.OrderCancelled as const,
          transaction: {
            id: transactionId,
            cancelled: true as const,
            isPaymentError: false as const,
            failed: false as const,
            ...transaction,
          },
        },
      });
    }),
  );
};

export default handler;

type OrderCancelledRouteData = {
  routeName: RouteName.OrderCancelled;
  params: {
    previewToken?: string;
    transactionId?: string;
  };
};
type OrderCancelledPage = ContentSystemPage & {
  component: PageComponentNames.OrderCancelled;
  transaction: CancelledTransaction;
};
type OrderCancelledPreviewResponse = {
  pages: {
    orderCancelled: ContentSystemPageData;
  };
};

//Ticket 187109: [SCA] 3.3.Order placement with chop products
type OrderCancelledPageResponse = {
  paymentTransaction: {
    checkoutProcessName: CheckoutProcessNames;
    document: {
      id: string;
      documentId: string;
      chopOrderNo: string;
    };
  };
} & OrderCancelledPreviewResponse;
