import loadable from '@loadable/component';

export default {
  ProfileAccountInformation: loadable(() => import(/*webpackChunkName:"my-acc"*/'./accountInformation')),
  ProfileNewOrderButton: loadable(() => import(/*webpackChunkName:"my-acc"*/'./newOrderButton')),
  ProfileNewProspectButton: loadable(() => import(/*webpackChunkName:"my-acc"*/'./newProspectButton')),
  ProfileRecentOrders: loadable(() => import(/*webpackChunkName:"my-acc"*/'./recentOrders')),
  ProfileUserGreetingMessage: loadable(() => import(/*webpackChunkName:"my-acc"*/'./userGreetingMessage')),
  AccountDetails: loadable(() => import(/*webpackChunkName:"my-acc"*/'./accountDetails')),
};
