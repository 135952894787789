import type { InvoiceOrder, InvoicePaymentPage, PaymentMethodInfo } from './types';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { invoicePaymentPageQuery } from './queries';
import { loadContentSystemPageQuery, initContentSystemPageContent, ContentSystemPageData } from 'behavior/pages/system';
import { Handler } from '../types';
import { RouteName } from 'routes';
import { order, paymentMethods } from './stubData';

const handler: Handler<InvoicePaymentRouteData, InvoicePaymentPage> = (routeData, _, { api }) => {
  const { params: { orderId, previewToken } } = routeData;

  if (previewToken)
    return api.graphApi<InvoicePaymentPreviewPageResponse>(loadContentSystemPageQuery('invoicePayment')).pipe(
      map(({ pages: { invoicePayment: page } }) => ({
        page: {
          component: PageComponentNames.InvoicePayment as const,
          order,
          paymentMethods,
          ...page,
        },
      })),
      initContentSystemPageContent(),
    );

  return api.graphApi<InvoicePaymentPageResponse>(invoicePaymentPageQuery, { orderId }).pipe(
    map(({
      pages: { invoicePayment: page },
      invoicePayment,
    }) => {
      const { order, paymentMethods } = invoicePayment;

      return {
        page: {
          component: PageComponentNames.InvoicePayment as const,
          order,
          paymentMethods: paymentMethods.list,
          ...page,
        },
      };
    }),
    initContentSystemPageContent(),
  );
};

export default handler;

type InvoicePaymentRouteData = {
  routeName: RouteName.InvoicePayment;
  params: {
    orderId?: string;
    previewToken?: string;
  };
};

type InvoicePaymentPageResponse = {
  pages: {
    invoicePayment: ContentSystemPageData;
  };
  invoicePayment: {
    order: InvoiceOrder;
    paymentMethods: {
      list:  PaymentMethodInfo[];
    };
  };
};

type InvoicePaymentPreviewPageResponse = {
  pages: {
    invoicePayment: ContentSystemPageData;
  };
};
