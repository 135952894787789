/* eslint-disable no-unused-expressions */
import PropTypes from 'prop-types';
import ExtendedAutosuggest from './ExtendedAutosuggest';
import { SimpleText, useSimpleTexts } from 'components/sanaText';
import AvailabilityStatus from './AvailabilityStatus';
import { useState } from 'react';

const Autosuggestion = props => {
  const {
    suggestions,
    onFetchRequested,
    onClearRequested,
    onSelected,
    onHighlighted,
    getItemValue,
    renderItem,
    renderItemsContainer,
    focusInputOnItemClick,
    inputProps,
    containerProps,
    showInfo = true,
    onBlurOnEnter = false,
    ...otherProps
  } = props;

  const [suggestionsLabel] = useSimpleTexts(['Search_Suggestions']).texts;
  const id = inputProps.id;
  const listId = id + '_list';
  const descriptionId = id + '_desc';

  const [inFocus, setInFocus] = useState(false);

  const onBlur = e => {
    setInFocus(false);
    inputProps.onBlur?.(e);
  };

  const onFocus = e => {
    setInFocus(true);
    inputProps.onFocus?.(e);
  };

  const extendedInputProps = {
    ...inputProps,
    onBlur,
    onFocus,
    'aria-describedby': inputProps.value ? null : descriptionId,
  };

  return (
    <>
      <ExtendedAutosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onFetchRequested}
        onSuggestionsClearRequested={onClearRequested}
        onSuggestionSelected={onSelected}
        onSuggestionHighlighted={onHighlighted}
        getSuggestionValue={getItemValue}
        renderSuggestion={renderItem}
        renderSuggestionsContainer={renderItemsContainer}
        focusInputOnSuggestionClick={focusInputOnItemClick || false}
        inputProps={extendedInputProps}
        listId={listId}
        containerProps={containerProps}
        listLabel={suggestionsLabel}
        onBlurOnEnter={onBlurOnEnter}
        {...otherProps}
      />
      {showInfo && (
        <>
          <div className="visually-hidden" id={descriptionId}>
            <SimpleText textKey="Search_Description" />
          </div>
          <AvailabilityStatus suggestions={suggestions} inFocus={inFocus} />
        </>
      )}
    </>
  );
};

Autosuggestion.propTypes = {
  suggestions: PropTypes.array.isRequired,
  onFetchRequested: PropTypes.func.isRequired,
  onClearRequested: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
  onHighlighted: PropTypes.func,
  getItemValue: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  renderItemsContainer: PropTypes.func,
  inputProps: PropTypes.object.isRequired,
  focusInputOnItemClick: PropTypes.bool,
  showInfo: PropTypes.bool,
  onBlurOnEnter: PropTypes.bool,
};

export default Autosuggestion;
