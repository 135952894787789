import type { Epic } from 'behavior/types';
import type { AccountDetailsAction } from './actions';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, mergeMap, pluck } from 'rxjs/operators';
import { ACCOUNT_DETAILS_INFO_REQUESTED, accountDetailsInfoReceived } from './actions';
import { accountDetailsQuery } from './queries';

const loadAccountDetailsInfoEpic: Epic<AccountDetailsAction> = (action$, _state$, { api }) => {
  return action$.pipe(
    ofType(ACCOUNT_DETAILS_INFO_REQUESTED),
    switchMap(() => api.graphApi(accountDetailsQuery).pipe(
      pluck('profile', 'accountDetails'),
      mergeMap(accountDetails => of(accountDetailsInfoReceived(accountDetails))),
    )),
  );
};

export default loadAccountDetailsInfoEpic;
