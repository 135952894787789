import type {
  CalculatedProductLoadOptions,
  CalculatedProduct,
  ProductReview,
  ProductReviewInput,
  VolumePrice,
  SalesAgreement,
  SalesAgreementLineAvailability,
} from './types';

export const UPDATE_PRODUCT_CALCULATED_FIELDS = 'PRODUCT/UPDATE/CALCULATED_FIELDS' as const;
export const updateCalculatedFields = (options: CalculatedProductLoadOptions, isChopAvailable = false) => ({
  type: UPDATE_PRODUCT_CALCULATED_FIELDS,
  payload: { options, isChopAvailable },
});

export const PRODUCT_CALCULATED_FIELDS_LOADED = 'PRODUCT/CALCULATED_FIELDS/LOADED' as const;
export const productCalculatedFieldsLoaded = (product: CalculatedProduct) => ({
  type: PRODUCT_CALCULATED_FIELDS_LOADED,
  payload: product,
});
export type ProductCalculatedFieldsLoadedAction = ReturnType<typeof productCalculatedFieldsLoaded>;

export const REVIEWS_REQUESTED = 'PRODUCT/REVIEWS/REQUESTED' as const;
export const requestReviews = (productId: string, pageIndex: number) => ({
  type: REVIEWS_REQUESTED,
  payload: { productId, pageIndex },
});

export const REVIEWS_RECEIVED = 'PRODUCT/REVIEWS/RECEIVED' as const;
export const reviewsReceived = (reviews: ProductReview[]) => ({
  type: REVIEWS_RECEIVED,
  payload: reviews,
});
export type ReviewsReceivedAction = ReturnType<typeof reviewsReceived>;

export const REVIEW_SUBMITTED = 'PRODUCT/REVIEW/SUBMITTED' as const;
export const sendReview = (data: ProductReviewInput) => ({ type: REVIEW_SUBMITTED, payload: data });

export const REVIEW_PROCESSED = 'PRODUCT/REVIEW/PROCESSED' as const;
export const reviewProcessed = (success: boolean) => ({
  type: REVIEW_PROCESSED,
  payload: success,
});
export type ReviewProcessedAction = ReturnType<typeof reviewProcessed>;

export const HAS_VOLUME_PRICES_REQUESTED = 'HAS_VOLUME_PRICES/REQUESTED' as const;
export const requestHasVolumePrices = (productId: string) => ({
  type: HAS_VOLUME_PRICES_REQUESTED,
  payload: { productId },
});

export const HAS_VOLUME_PRICES_RECEIVED = 'HAS_VOLUME_PRICES/RECEIVED' as const;
export const hasVolumePricesReceived = (hasVolumePrices: boolean) => ({
  type: HAS_VOLUME_PRICES_RECEIVED,
  payload: { hasVolumePrices },
});
export type HasVolumePriceReceivedAction = ReturnType<typeof hasVolumePricesReceived>;

export const VOLUME_PRICES_REQUESTED = 'VOLUME_PRICES/REQUESTED' as const;
export const requestVolumePrices = (productId: string, variantId: string | null | undefined, uomId: string | null) => ({
  type: VOLUME_PRICES_REQUESTED,
  payload: { productId, variantId, uomId },
});

export const VOLUME_PRICES_RECEIVED = 'VOLUME_PRICES/RECEIVED' as const;
export const volumePriceReceived = (volumePrices: { prices: VolumePrice[]; variantId: string | null | undefined; uomId: string | null }) => ({
  type: VOLUME_PRICES_RECEIVED,
  payload: volumePrices,
});
export type VolumePriceReceivedAction = ReturnType<typeof volumePriceReceived>;

export const SALES_AGREEMENT_REQUESTED = 'PRODUCT/SALES_AGREEMENT_REQUESTED' as const;
export const requestSalesAgreement = (agreementId: string, productId: string) => ({
  type: SALES_AGREEMENT_REQUESTED,
  payload: { agreementId, productId },
});

export const SALES_AGREEMENT_RECEIVED = 'PRODUCT/SALES_AGREEMENT_RECEIVED' as const;
export const receiveSalesAgreement = (
  productId: string,
  agreement: SalesAgreement,
  linesAvailability: SalesAgreementLineAvailability[] | null,
  canViewUom: boolean,
  allowUomSelection: boolean,
  productUom: { id: string } | null,
  productUoms: { id: string }[] | null,
) => ({
  type: SALES_AGREEMENT_RECEIVED,
  payload: { productId, agreement, linesAvailability, canViewUom, allowUomSelection, productUom, productUoms },
});
export type SalesAgreementReceivedAction = ReturnType<typeof receiveSalesAgreement>;

export const SALES_AGREEMENT_PRODUCT_VARIANT_CHANGED = 'PRODUCT/SALES_AGREEMENT_PRODUCT_VARIANT_CHANGED' as const;
export const changeProductVariantForSalesAgreement = (variantId: string, canViewUom: boolean, allowUOMSelection: boolean) => ({
  type: SALES_AGREEMENT_PRODUCT_VARIANT_CHANGED,
  payload: { variantId, canViewUom, allowUOMSelection },
});
export type ChangeProductVariantForSalesAgreementAction = ReturnType<typeof changeProductVariantForSalesAgreement>;

export type ProductPageAction = ReturnType<
  | typeof updateCalculatedFields
  | typeof productCalculatedFieldsLoaded
  | typeof requestReviews
  | typeof reviewsReceived
  | typeof sendReview
  | typeof reviewProcessed
  | typeof requestHasVolumePrices
  | typeof requestVolumePrices
  | typeof volumePriceReceived
  | typeof requestSalesAgreement
  | typeof receiveSalesAgreement
  | typeof changeProductVariantForSalesAgreement
>;
