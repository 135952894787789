export const quickOrderPageSize = 10;

export const RebateExact = '1';
export const ArtOfMat = '2';
export const ExternalExact = '3'; 

export const NonReversibleMoulding = '0';
export const ReversibleLongSideup = '1';
export const ReversibleShortSideup = '2';

export const Set1 = 'Set1';
export const Set2 = 'Set2';