export const SHIPPING_ADDRESS_DELETE = 'SHIPPING_ADDRESS_DELETE' as const;
export const shippingAddressDelete = (id: string) => ({
  type: SHIPPING_ADDRESS_DELETE,
  payload: { id },
});

export const SHIPPING_ADDRESS_DELETED = 'SHIPPING_ADDRESS_DELETED' as const;
export const shippingAddressDeleted = (id: string) => ({
  type: SHIPPING_ADDRESS_DELETED,
  payload: { id },
});

export type ShippingAddressDeletedAction = ReturnType<typeof shippingAddressDeleted>;
export type ShippingAddressDeleteAction = ReturnType<typeof shippingAddressDelete>;