import type { Product, SpecificationSettings, Specification, SpecificationValue } from './types';

const excludeUndefined = <T>(x: T | undefined): x is T => !!x;
export const sortByIds = (products: Product[] | undefined, ids: string[] = []): Product[] => {
  if (!products || !products.length)
    return [];
  return ids.map(id => products.find(p => p.id.toLocaleUpperCase() === id.toLocaleUpperCase())).filter(excludeUndefined);
};

export const buildSpecificationsModel = (specifications: SpecificationSettings | undefined, products: Product[] | undefined): Specification[] | undefined => {
  if (!products || !products.length || !specifications || !specifications.length)
    return undefined;

  const specs: Specification[] = [];
  specifications.forEach(({ key, name }) => {
    const values: SpecificationValue[] = [];
    let hasDifferences = false;
    let sanaTextKey: string | undefined;

    products.forEach(p => {
      const spec = p.specifications && p.specifications.find(s => s.key === key);

      if (spec) {
        values.push({
          sanaTextKey: spec.valueTextKey,
          value: spec.value,
          valueString: spec.valueString,
        });
        name = spec.name;
        sanaTextKey = spec.titleTextKey;
      } else {
        values.push({
          value: null,
          valueString: null,
        });
      }
      hasDifferences = !values.every(v => v.valueString === values[0].valueString);
    });

    if (values.some(v => v.value !== null)) {
      specs.push({
        key,
        name,
        values,
        sanaTextKey,
        hasDifferences,
      });
    }
  });

  return specs.length ? specs : undefined;
};

export const removeProductFromSpecificationsModel = (specifications: Specification[], productIndex: number): Specification[] => {
  if (!specifications || !specifications.length)
    return specifications;

    //Ticket 198541: [SCA][RFC] - Multi - value attribute support
  specifications.forEach(spec => {
    spec.values && spec.values.splice(productIndex, 1);
    spec.hasDifferences = !spec.values.every(v => v.valueString === spec.values[0].valueString);
  });

  return specifications.filter(spec => spec.values.some(v => v.value !== null));
};
