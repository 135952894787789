import Autosuggest from 'react-autosuggest/dist';
import { mapToAutowhateverTheme } from 'react-autosuggest/dist/theme';
import ExtendedAutowhatever from './ExtendedAutowhatever';

const REASON_SUGGESTIONS_REVEALED = 'suggestions-revealed';
const REASON_INPUT_FOCUSED = 'input-focused';
const REASON_INPUT_CHANGED = 'input-changed';
const REASON_ESCAPE_PRESSED = 'escape-pressed';

export default class ExtendedAutosuggest extends Autosuggest {
  render() {
    const {
      suggestions,
      renderInputComponent,
      onSuggestionsFetchRequested,
      renderSuggestion,
      inputProps,
      multiSection,
      renderSectionTitle,
      id,
      getSectionSuggestions,
      theme,
      getSuggestionValue,
      alwaysRenderSuggestions,
      highlightFirstSuggestion,
      containerProps,
      listId,
      listLabel,
      onBlurOnEnter,
    } = this.props;
    const {
      isFocused,
      isCollapsed,
      highlightedSectionIndex,
      highlightedSuggestionIndex,
      valueBeforeUpDown,
    } = this.state;
    const shouldRenderSuggestions = alwaysRenderSuggestions
      ? () => true
      : this.props.shouldRenderSuggestions;
    const { value, onFocus, onKeyDown } = inputProps;
    const willRenderSuggestions = this.willRenderSuggestions(
      this.props,
      'render',
    );
    const isOpen =
      alwaysRenderSuggestions ||
      (isFocused && !isCollapsed && willRenderSuggestions);
    const items = isOpen ? suggestions : [];
    const autowhateverInputProps = {
      ...inputProps,
      onFocus: event => {
        if (
          !this.justSelectedSuggestion &&
          !this.justClickedOnSuggestionsContainer
        ) {
          const shouldRender = shouldRenderSuggestions(
            value,
            REASON_INPUT_FOCUSED,
          );

          this.setState({
            isFocused: true,
            isCollapsed: !shouldRender,
          });

          onFocus && onFocus(event);

          if (shouldRender) {
            onSuggestionsFetchRequested({
              value,
              reason: REASON_INPUT_FOCUSED,
            });
          }
        }
      },
      onBlur: event => {
        if (this.justClickedOnSuggestionsContainer) {
          this.input.focus();
          return;
        }

        this.blurEvent = event;

        if (!this.justSelectedSuggestion) {
          this.onBlur();
          this.onSuggestionsClearRequested();
        }
      },
      onChange: event => {
        const { value } = event.target;
        const shouldRender = shouldRenderSuggestions(
          value,
          REASON_INPUT_CHANGED,
        );

        this.maybeCallOnChange(event, value, 'type');

        if (this.suggestionsContainer) {
          this.suggestionsContainer.scrollTop = 0;
        }

        this.setState({
          ...(highlightFirstSuggestion
            ? {}
            : {
              highlightedSectionIndex: null,
              highlightedSuggestionIndex: null,
              highlightedSuggestion: null,
            }),
          valueBeforeUpDown: null,
          isCollapsed: !shouldRender,
        });

        if (shouldRender) {
          onSuggestionsFetchRequested({ value, reason: REASON_INPUT_CHANGED });
        } else {
          this.onSuggestionsClearRequested();
        }
      },
      onKeyDown: (event, data) => {
        const { keyCode } = event;

        switch (keyCode) {
          case 40: // ArrowDown
          case 38: // ArrowUp
            if (isCollapsed) {
              if (shouldRenderSuggestions(value, REASON_SUGGESTIONS_REVEALED)) {
                onSuggestionsFetchRequested({
                  value,
                  reason: REASON_SUGGESTIONS_REVEALED,
                });
                this.revealSuggestions();
                event.preventDefault(); // We act on the key.
              }
            } else if (suggestions.length > 0) {
              const {
                newHighlightedSectionIndex,
                newHighlightedItemIndex,
              } = data;

              let newValue;

              if (newHighlightedItemIndex === null) {
                // valueBeforeUpDown can be null if, for example, user
                // hovers on the first suggestion and then pressed Up.
                // If that happens, use the original input value.
                newValue =
                  valueBeforeUpDown === null ? value : valueBeforeUpDown;
              } else {
                newValue = this.getSuggestionValueByIndex(
                  newHighlightedSectionIndex,
                  newHighlightedItemIndex,
                );
              }

              this.updateHighlightedSuggestion(
                newHighlightedSectionIndex,
                newHighlightedItemIndex,
                value,
              );
              this.maybeCallOnChange(
                event,
                newValue,
                keyCode === 40 ? 'down' : 'up',
              );
              event.preventDefault(); // We act on the key.
            }

            this.justPressedUpDown = true;

            setTimeout(() => {
              this.justPressedUpDown = false;
            });

            break;

          // Enter
          case 13: {
            // See #388
            if (event.keyCode === 229) {
              break;
            }

            const highlightedSuggestion = this.getHighlightedSuggestion();

            if (isOpen && !alwaysRenderSuggestions) {
              this.closeSuggestions();
            }

            if (onBlurOnEnter && highlightedSuggestion == null) {
              const inputs = document.querySelectorAll('.' + this.input.className);
              const nextInputIndex = Array.prototype.indexOf.call(inputs, this.input) + 1;

              if (inputs.length !== nextInputIndex) {
                inputs[nextInputIndex].focus();
              }
              else {
                this.onBlur();
              }

              break;
            }

            if (highlightedSuggestion != null) {
              event.preventDefault();
              const newValue = getSuggestionValue(highlightedSuggestion);

              this.maybeCallOnChange(event, newValue, 'enter');

              this.onSuggestionSelected(event, {
                suggestion: highlightedSuggestion,
                suggestionValue: newValue,
                suggestionIndex: highlightedSuggestionIndex,
                sectionIndex: highlightedSectionIndex,
                method: 'enter',
              });

              this.justSelectedSuggestion = true;

              setTimeout(() => {
                this.justSelectedSuggestion = false;
              });
            }

            break;
          }

          // Escape
          case 27: {
            if (isOpen) {
              // If input.type === 'search', the browser clears the input
              // when Escape is pressed. We want to disable this default
              // behaviour so that, when suggestions are shown, we just hide
              // them, without clearing the input.
              event.preventDefault();
            }

            const willCloseSuggestions = isOpen && !alwaysRenderSuggestions;

            if (valueBeforeUpDown === null) {
              // Didn't interact with Up/Down
              if (!willCloseSuggestions) {
                const newValue = '';

                this.maybeCallOnChange(event, newValue, 'escape');

                if (shouldRenderSuggestions(newValue, REASON_ESCAPE_PRESSED)) {
                  onSuggestionsFetchRequested({
                    value: newValue,
                    reason: REASON_ESCAPE_PRESSED,
                  });
                } else {
                  this.onSuggestionsClearRequested();
                }
              }
            } else {
              // Interacted with Up/Down
              this.maybeCallOnChange(event, valueBeforeUpDown, 'escape');
            }

            if (willCloseSuggestions) {
              this.onSuggestionsClearRequested();
              this.closeSuggestions();
            } else {
              this.resetHighlightedSuggestion();
            }

            break;
          }
          default: return;
        }

        onKeyDown && onKeyDown(event);
      },
    };
    const renderSuggestionData = {
      query: this.getQuery(),
    };

    return (
      <ExtendedAutowhatever
        multiSection={multiSection}
        items={items}
        renderInputComponent={renderInputComponent}
        renderItemsContainer={this.renderSuggestionsContainer}
        renderItem={renderSuggestion}
        renderItemData={renderSuggestionData}
        renderSectionTitle={renderSectionTitle}
        getSectionItems={getSectionSuggestions}
        highlightedSectionIndex={highlightedSectionIndex}
        highlightedItemIndex={highlightedSuggestionIndex}
        containerProps={containerProps}
        inputProps={autowhateverInputProps}
        itemProps={this.itemProps}
        theme={mapToAutowhateverTheme(theme)}
        id={id}
        ref={this.storeAutowhateverRef}
        listId={listId}
        listLabel={listLabel}
      />
    );
  }
}
