import { LOCATION_CHANGED } from 'behavior/events';
import { ofType } from 'redux-observable';
import { Epic } from 'behavior/types';
import { ShippingAddressDeleteAction, shippingAddressDeleted, SHIPPING_ADDRESS_DELETE } from './actions';
import { switchMap, takeUntil, mergeMap, startWith } from 'rxjs/operators';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { deleteShippingAddress } from './queries';
import { of } from 'rxjs';

const epic: Epic<ShippingAddressDeleteAction> = (action$, _state$, { api, logger }) => {
  const locationChanged$ = action$.pipe(
    ofType(LOCATION_CHANGED),
  );

  const deleteAddress$ = action$.pipe(
    ofType(SHIPPING_ADDRESS_DELETE),
    switchMap(action => {
      return api.graphApi(deleteShippingAddress, { id: action.payload.id }, { retries: 1 }).pipe(
        mergeMap(() => of(shippingAddressDeleted(action.payload.id), unsetLoadingIndicator())),
        takeUntil(locationChanged$),
        retryWithToast(action$, logger),
        startWith(setLoadingIndicator()),
      );
    }),
  );

  return deleteAddress$;
};

export default epic;