import {
  openOrdersQuery,
} from './queries';
import {
  OPEN_ORDERS_REQUESTED,
    openOrdersReceived,
    openOrdersAction,
} from './actions';
import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { map, pluck, exhaustMap } from 'rxjs/operators';

const loadOpenOrdersEpic: Epic<openOrdersAction> = (action$, _, { api }) => action$.pipe(
    ofType(OPEN_ORDERS_REQUESTED),
    pluck('payload'),
        exhaustMap(({ options }) => api.graphApi(openOrdersQuery, { options }).pipe(
        map(openOrdersReceived),
    ),
    ),
);

export default loadOpenOrdersEpic;